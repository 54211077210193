import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import GetPrefix from "../helpers/GetPrefix"
import Box from "@material-ui/core/Box"

const BasicPageSidebar = ({ pageContent, language }) => {
  const subpages = R.path(["subpages"], pageContent)
  const folderSlug = R.path(["folderSlug"], pageContent)
  const slug = R.path(["slug"], pageContent)
  let wholeSlug
  if (folderSlug) {
    wholeSlug = GetPrefix(language, `${folderSlug}/${slug}`)
  } else {
    wholeSlug = GetPrefix(language, `${slug}`)
  }

  return (
    <Box
      className="sidebarlinks"
      style={{
        backgroundColor: "#f1f9fb",
        padding: "15px",
        marginBottom: "30px",
      }}
    >
      {subpages &&
        subpages.map(item => (
          !item.hideSubpage &&
          <div key={R.path(["title"], item)} className="sidebarlinkholder">
            { R.path(["title"], item) === 'Kohteet' ? (
            <Link
              className="sidebar"
              style={{ padding: "5px" }}
              to={`${wholeSlug}/kohteet`}
            >
              {R.path(["title"], item)}
            </Link>
            ) : (
            <Link
              className="sidebar"
              partiallyActive={true}
              activeStyle={{ backgroundColor: "#fae600" }}
              style={{ padding: "5px" }}
              to={`${wholeSlug}/${R.path(["slug"], item)}#${item.title}`}
            >
              {R.path(["title"], item)}
            </Link>
            )}
          </div>
        ))}
    </Box>
  )
}

export default BasicPageSidebar


